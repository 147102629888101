export default {
  namespaced: true,
  state:{
    shareData: {},
    wxConfig: {},
  },
  mutations:{
    setShareData(state,data){
      state.shareData = data
    },
    setWxConfig(state,data){
      state.wxConfig = data
    },

  },
  actions:{

  },
  getters:{

  }
}
