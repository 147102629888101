import { get, post } from '@/utils/request'

/*
* 登录获取短信
**/
export const SendValidCode = (tel) => {
  return get('/login/userVerification', { account: tel })
    .then(({ data }) => {
      return data
    })
    .catch(err => {
      return err
    })
}

/*
* 根据code拿openid
**/
export const getOpenId = (code) => {
  return get('/login/getOpenId', { code })
    .then((res) => {
      return res
    })
    .catch(err => {
      return err
    })
}

export const userLogin = obj => {
  return post('/login/userLogin', obj)
    .then(({ data }) => {
      return data
    })
    .catch(err => {
      return err
    })
}


export const userWxLogin = (code) => {
  return post('/login/userWxLogin',{ code })
    .then(({ data }) => {
      return data
    })
    .catch(err => {
      return err
    })
}
