export default {
  namespaced: true,
  state:{
    historyList: [],
  },
  mutations:{
    setHistoryList(state, data) {
      state.historyList = data;
    },
  },
  actions:{
  },
  getters:{
  }
}
