import request from '@/utils/request'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'

async function wxShareApi(path, imgurls, titles, descs) { //分享主题信息, 分享图片地址,  分享标题,  分享描述,   分享链接地址
  const host = window.location.host          //当前域名
  const protocal = window.location.protocol  //当前协议
  const pathUrl = path || encodeURIComponent(window.location.href);      //返回当前页面的路径和文件名
  const res = await request.get("/share/wxShareConfig", {
    params: {
      pathUrl:pathUrl
    }
  }).catch(r =>{
    alert(r)
    return
  })
  const appId = res.data.data.appId
  const debug = false
  const timestamp = res.data.data.timestamp
  const nonceStr = res.data.data.nonceStr
  const signature = res.data.data.signature
  const jsApiList = ['onMenuShareTimeline', 'onMenuShareAppMessage',
    'getNetworkType', 'openLocation', 'getLocation', 'hideOptionMenu', 'showOptionMenu',
    'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem', 'showAllNonBaseMenuItem',
    'closeWindow', 'scanQRCode', 'chooseWXPay'
  ]

  // const jsApiList = ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo',
  //   'onMenuShareQZone', 'startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice',
  //   'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'uploadVoice', 'downloadVoice',
  //   'chooseImage', 'previewImage', 'uploadImage', 'downloadImage', 'translateVoice',
  //   'getNetworkType', 'openLocation', 'getLocation', 'hideOptionMenu', 'showOptionMenu',
  //   'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem', 'showAllNonBaseMenuItem',
  //   'closeWindow', 'scanQRCode', 'chooseWXPay'
  // ]

  // console.log(res.data.data.appId);
  wx.config({
    debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: appId, // 必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录1
    // jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    jsApiList: jsApiList
  });
  wx.error(function (res) {

    // console.log("验证失败返回的信息", res);
  })
  // console.log("输出分享信息",titles,descs,linkUrl,imgurls);
  wx.ready(function () {
    let config = {
      title: titles, // 分享标题
      desc: descs, // 分享描述
      link: pathUrl, // 分享链接
      imgUrl: imgurls, // 分享图标
      success: function () {
        // 用户确认分享后执行的回调函数
        Toast.success("分享成功")
      },
      cancel: function () {
        // 用户取消分享后执行的回调函数
        Toast.fail("分享失败")
      }
    }
    // 分享好友
    wx.onMenuShareAppMessage(config);
    //分享朋友圈
    wx.onMenuShareTimeline(config);
    wx.hideMenuItems({
      menuList: ["menuItem:share:qq","menuItem:share:weiboApp",
        "menuItem:share:facebook","menuItem:share:QZone", "menuItem:copyUrl","menuItem:openWithQQBrowser","menuItem:openWithSafari","menuItem:originPage"]
    })
    // // 分享给qq
    // wx.onMenuShareQZone(config)
    // // 分享到微博
    // wx.onMenuShareWeibo(config)
    // // 分享到qq空间
    // wx.onMenuShareQZone(config)
  });
}


export default wxShareApi
