//使用正则获取地址栏参数

// export function Url(name) {
//   const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
//   const r = window.location.search.substr(1).match(reg);
//   if (r != null) return unescape(r[2]);
//   return null;
// }

import qs from 'qs';
/* hash路由 */
export function getQueryParam(paramName) {
  const searchParams = new URLSearchParams(window.location.search); // 获取 URL 中的查询参数
  return searchParams.get(paramName) || null; // 返回指定键的参数值
}



