import Vue from 'vue'
import {
  Button, Tabbar, TabbarItem, NavBar, Toast, Search, Swipe, SwipeItem,
  Grid, GridItem, Card, Cell, CellGroup, Icon, Tab, Tabs, Dialog, Rate,
  ActionSheet, Image,Empty,Divider,List,PullRefresh,Sidebar,SidebarItem,
  Field,Form,Popover,SubmitBar,CountDown,Notify,NoticeBar,NumberKeyboard,
  Radio,RadioGroup
} from 'vant'

Vue.use(Toast)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Card)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Dialog)
Vue.use(Rate)
Vue.use(ActionSheet)
Vue.use(Image)
Vue.use(Empty)
Vue.use(Divider)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Field)
Vue.use(Form)
Vue.use(Popover)
Vue.use(SubmitBar)
Vue.use(CountDown)
Vue.use(Notify)
Vue.use(NoticeBar)
Vue.use(NumberKeyboard)
Vue.use(Radio);
Vue.use(RadioGroup);

