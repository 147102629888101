export default {
  namespaced: true,
  state:{
    templeInfoData: {},
    templeId: '',
  },
  mutations:{
    setTempleData(state,payload){
      state.templeInfoData = payload
      state.templeId = payload.templeId
    },
    setTempleId(state,payload){
      state.templeId = payload
    },
    setTempleContent(state,payload){
      state.templeInfoData.templeConment = payload
    },

  },
  actions:{

  },
  getters:{
    templeImgArr(state){
      return state.templeInfoData?.templeImgUrl?.split(',') || [];
    },
    getTempleName(state){
      return state.templeInfoData?.templeName || ''
    },
    getTempleId(state){
      return state.templeId || state.templeInfoData?.templeId || ''
    }
  }
}
