export default {
  namespaced: true,
  state:{
    ispaying: false  //支付按钮  是否正在支付
  },
  mutations:{
    setPaying(state,data){
      state.ispaying = data
    }

  },
  actions:{

  },
  getters:{

  }
}
