import { getOpenId } from '@/api/modules/login'
import dayjs from 'dayjs'

export default {
  namespaced: true,
  state: {
    userInfo: {},
    openId: '',
    alicode:'',
    loginTime: '',

  },
  mutations: {
    setUserInfo(state,data){
      state.userInfo = data
    },
    setOpenId (state, data) {
      state.openId = data
    },
    setAliCode(state,data){
      state.alicode = data
    },
    setLoginTime (state, data) {
      state.loginTime = data
    },

  },
  actions: {
    // 首页寺庙订单
    async getOpenId ({ commit }, code) {
      try {
        const { data } = await getOpenId(code) // 异步获取 OpenId
        console.log(data.data) // 打印获取到的数据
        // 提交 mutation 或者直接返回数据
        commit('setOpenId', data.data.openid) // 如果需要将数据提交到 mutation 中
        commit('setLoginTime', dayjs().format('YYYY-MM-DD HH:mm:ss'))
        return data.data // 直接返回获取到的 OpenId 数据
      } catch (error) {
        console.error('Failed to fetch OpenId', error)
        throw error // 抛出异常以便上层处理
      }
    },
  },
  getters: {}
}
