export default {
  namespaced: true,
  state:{
    SET_ENV_TYPE: ''
  },
  mutations:{
    setPayType(state, data) {
      state.SET_ENV_TYPE = data;
    },
  },
  actions:{
  },
  getters:{
    determineEnvironment: (state) => (payType) => {
      const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
      const isAlipay = /AlipayClient/i.test(navigator.userAgent);
      let type
      if (isWeChat) {
        type = payType === 0 ? 'GFWX' : 'DLBWX'
      } else if (isAlipay) {
        type = payType === 0 ? 'GFALIPAY' : 'DLBALIPAY'
      } else {
        type = 'H5'
      }
      return type;
    }
  }
}
