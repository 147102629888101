import store from '@/store'
export default {
  namespaced: true,
  state:{
      comboList:[],
  },
  mutations:{
      setComboList(state,data){
        state.comboList = data
      },

  },
  actions:{

  },
  getters:{
    // 获取特定类型的套餐数据
    combosOfType: (state) => (type) => {
      return state.comboList.filter(combo => combo.comboType === type);
    },
    // 获取满堂红的套餐数据
    combosOfAllred: state => {
      return state.comboList.filter(combo => combo.comboType === '0');
    },
    // // 获取 comboType 为 1 的套餐数据
    // combosOfOne: state => {
    //   return state.comboList.filter(combo => combo.comboType === '1');
    // },
    // // 获取 comboType 为 3 的套餐数据
    // combosOfThree: state => {
    //   return state.comboList.filter(combo => combo.comboType === '3');
    // },
    // // 获取 comboType 为 4 的套餐数据
    // combosOfFour: state => {
    //   return state.comboList.filter(combo => combo.comboType === '4');
    // },
    //根据id找到combo
    getComboById: (state) =>(comboId)=>{
      return state.comboList.find(combo => combo.comboId === comboId)
    },
  }
}
