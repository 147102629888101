import request from '@/utils/request'


/*
* 查询分享文案
* */
export const getShare = ()=>{
  return request.get('/share/getShare').catch(r=>{
    console.log(r)
  }).catch(r=>{
    alert(r)
  })
}


