export default {
  namespaced: true,
  state:{
    deviceListData: [],
    finished: false,
    device: {},
  },
  mutations:{
    setDeviceListData(state,data){
      state.deviceListData.push(...data);
    },

    removeDeviceListData(state){
      state.deviceListData = []
    },

    setFinished(state,data){
      state.finished = data
    },

    setDevice(state,data){
      state.device = data
    },
    setDeviceStatusMap(state,data){
      state.device.deviceStatusMap = data
    },
    updateDeviceSignal(state,data){
      state.device.deviceSignal = data
    }

  },
  actions:{

  },
  getters:{
    getDeviceItem: (state) => (deviceImei) => {
      return state.deviceListData.find(obj => obj.deviceImei === deviceImei)
    }
  }
}
