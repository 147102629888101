import store from '@/store'
export default {
  namespaced: true,
  state:{
    productQr: {}
  },
  mutations:{
    setProductQr(state,data){
      state.productQr = data
    },

  },
  actions:{

  },
  getters:{

  }
}
