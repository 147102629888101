import request from '@/utils/request'

/*
*   获取寺庙对象
*  */
export const getTempleInfoData = (templeId) => {
  return request.get('/temple/getTempleInfoById', {
    params: {
      templeId
    }
  }).catch(r=>{
    console.log(r)
  })
}

export const getTempleContent = (templeId) =>{
  return request.get('/temple/getTempleContent',{
    params: {
      templeId
    }
  }).catch(r=>{
    console.log(r)
  })
}
